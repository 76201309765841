import './App.css';
import Heading from './components/Heading';
import Icon from './components/Icon';

/* 
  1. Find the number of weeks elapsed between originTime and now => INT
  2. Filter TRUE or FALSE based on even or odd week
  3. Change assets based on even or odd week result
*/

function App() {
  
  const originTime = new Date("2023-01-01");
  const now = new Date();

  const numWeeks = Math.floor((now.getTime() - originTime.getTime())/(1000*60*60*24)/7);
  
  // TRUE   == Even weeks - Garbage week
  // FALSE  == Odd weeks - Recycling week
  const diffWeeks = (numWeeks % 2 === 0) ? true : false;
  
  return (
    <div className={`ctn ${(diffWeeks) ? "ctn-bg-garbage" : "ctn-bg-recycle"}`}>
      <Heading />
      <Icon addIcon={diffWeeks} />
    </div>
  );
}

export default App;
