import React from 'react'

function Icon({ addIcon }) {

  const icon = (addIcon) ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 413.38 454.72"><defs><style></style></defs><g id="Layer_2" data-name="Layer 2"><g id="garbage"><rect x="130.9" y="158.46" width="41.34" height="206.69"/><rect x="241.14" y="158.46" width="41.34" height="206.69"/><path d="M282.48,68.9V0H130.9V68.9H0v41.33H34.45V454.72H378.93V110.23h34.45V68.9ZM172.24,41.34h68.9V68.9h-68.9Zm165.35,372H75.79V110.23h261.8V413.38Z"/></g></g></svg>
  : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 441.69 432.91"><defs><style></style></defs><g id="Layer_2" data-name="Layer 2"><g id="recycling"><path d="M434.14,219s19.58,44.16-15.63,64.07c0,0-13.53,13.56-57.47,9.46l-43.12-74.75,86.24-50.1ZM283.49,0s22.74.65,47.91,41.2l27.39,46.42,19.37-10.46L358.08,121,338,165l-47.7-7.3-47.72-7.3,28-15.13-48-88.93S204.17,4.42,170.58.15ZM59.93,376.64,2.7,279.35S-8.25,259.41,14,217.2l26.16-47.14-18.82-11.4,48-4.92,48-4.91,17.83,44.84,17.87,44.84L125.84,222,73.43,308.45S46.62,345.53,59.93,376.64Zm121.16,32.28-59.46-1s-47.91-6.11-46.7-46.56c0,0-4.55-18.61,21.74-54.05l86.27,1.86ZM166.58,136.71,81.52,84.64l30.93-50.78S142-4.35,176.23,17.15c0,0,18.37,5.5,35.64,46.11ZM388.18,386.3s-11.39,19.67-59,22.54l-53.88,2v22L246.2,394.39l-29.1-38.51,29.1-38.51,29.08-38.51V310.7h101.1s45.58,3.67,65.31-23.84Z"/></g></g></svg>
  ;  

  return (
    <div className='icon'>
       { icon }
    </div>
  )
}

export default Icon